import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { useState } from "react"
import { ButtonOrderDisplay, WhiteButton } from "../components/buttons"
import { CardOrder } from "../components/card"

import Seo from "../components/seo"
import useBakery from "../hooks/use-bakery"

const BakeryPage = () => {
  const [display, setDisplay] = useState(false)
  const dataBakery = useBakery()
  const handleSubmit = () => setDisplay(true)

  return (
    <>
      <div className="flex flex-col items-start pt-4 lg:pt-0 px-4 bg-white dark:bg-main">
        <Seo title="Bakery" />
        <div className="grid place-items-center gap-2 lg:gap-4 mx-auto">
          <h1 className="xl:text-9xl pt-4 lg:pt-16">Āina</h1>
          <h2 className="pb-8 lg:pb-16 text-xl lg:text-4xl tracking-normal">
            Sourdough breads
          </h2>
          {/* {dataBakery.map(
            data =>
              data.title === "Seeds Hero" && (
                <GatsbyImage
                  image={data.imagePrimary}
                  placeholder="tracedSVG"
                  alt="sourdough bread image"
                  className="rounded-xl w-1/2"
                />
              )
          )} */}
        </div>
        <div className="columns-2 sm:columns-3 md:columns-4 lg:columns-5 xl:columns-6 2xl:columns-7 gap-4 mx-auto space-y-4 py-4">
          {dataBakery.map(data => (
            <div className="max-w-[236px] space-y-2 ">
              {data.orientation === "square" && (
                <GatsbyImage
                  image={data.imagePrimary}
                  placeholder="tracedSVG"
                  alt="sourdough bread image"
                  className="rounded-xl w-full aspect-square"
                />
              )}
              {data.orientation === "video" && (
                <GatsbyImage
                  image={data.imagePrimary}
                  placeholder="tracedSVG"
                  alt="sourdough bread image"
                  className="rounded-xl w-full aspect-video"
                />
              )}
            </div>
          ))}
        </div>
        {/* <div className="mx-auto p-4">
          {!display && (
            <button onClick={handleSubmit}>
              <ButtonOrderDisplay />
            </button>
          )}
        </div>
        {display && <CardOrder />} */}
      </div>
    </>
  )
}

export default BakeryPage
