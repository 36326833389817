import { useStaticQuery, graphql } from "gatsby"
import React from "react"

const useBakery = () => {
  const data = useStaticQuery(graphql`
    query MyBakeryQuery {
      allContentfulBakery {
        nodes {
          id
          title
          imagePrimary {
            gatsbyImageData
          }
          orientation
        }
      }
    }
  `)
  return data.allContentfulBakery.nodes.map(bakeryDataCard => ({
    title: bakeryDataCard.title,
    imagePrimary: bakeryDataCard.imagePrimary.gatsbyImageData,
    orientation: bakeryDataCard.orientation,
  }))
}

export default useBakery
